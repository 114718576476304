<template>
  <Modal @closeModal="closeModal">
    <Tile class="overflow-y-auto p-8" style="max-height: 60vh">
      <Title :title="campaign.title" size="medium" class="mb-4 text-center" />

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <KPI
          label="Ad views"
          :value="getTotalViews"
          title="Total ad views"
          style="background-color: rgba(0, 0, 0, 0.2) !important"
        />

        <KPI
          label="Average viewers"
          :value="getTotalAverageViews"
          title="Average amount of views for each ad display"
          style="background-color: rgba(0, 0, 0, 0.2) !important"
        />

        <KPI
          label="Link clicks"
          :value="getTotalClick"
          title="Amount of times campaign link has been clicked"
          style="background-color: rgba(0, 0, 0, 0.2) !important"
        />

        <KPI
          label="Link CTR"
          :value="getTotalLinkCtr"
          unit="%"
          title="Click-through rate ((clicks / views) * 100)"
          style="background-color: rgba(0, 0, 0, 0.2) !important"
        />

        <KPI
          label="Times displayed"
          :value="getTotalTimesDisplayed"
          title="Amount of times ad has been displayed"
          style="background-color: rgba(0, 0, 0, 0.2) !important"
        />

        <KPI
          label="Earned"
          :value="`€${calculateEarnings}`"
          title="Total money earned from campaign views"
          style="background-color: rgba(0, 0, 0, 0.2) !important"
        />
      </div>

      <div class="flex justify-center items-center mt-4 w-full">
        <Button kind="primary" label="Close" @clicked="closeModal" />
      </div>
    </Tile>
  </Modal>
</template>

<script>
import { KPI, Title, Button, Tile } from "cavea-design-system";

export default {
  name: "CampaignStatistics",

  components: {
    Title,
    Tile,
    Button,
    Modal: () => import("@/components/Modal"),
    KPI,
  },

  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },

  computed: {
    /**
     * @returns {number}
     */
    getTotalViews() {
      let views = 0;

      if (this.campaign?.handles[0]?.statsId?.stats) {
        views = this.campaign.handles[0].statsId.stats.reduce((a, b) => a + (b.views || 0), 0);
      }

      return views;
    },

    /**
     * @returns {number}
     */
    getTotalClick() {
      let clicks = 0;

      if (this.campaign?.handles[0]?.redirectorId?.stats?.length) {
        clicks = this.campaign.handles[0].redirectorId.stats.length;
      }

      return clicks;
    },

    /**
     * @returns {number}
     */
    getTotalTimesDisplayed() {
      let displayTimes = 0;

      if (this.campaign?.handles[0]?.statsId?.stats?.length) {
        displayTimes = this.campaign.handles[0].statsId.stats.length;
      }

      return displayTimes;
    },

    /**
     * @returns {number} float
     */
    getTotalAverageViews() {
      let avg = 0;

      if (this.getTotalViews && this.getTotalTimesDisplayed) {
        avg = parseFloat((this.getTotalViews / this.getTotalTimesDisplayed).toFixed(1));
      }

      return avg;
    },

    /**
     * @returns {number}
     */
    getTotalLinkCtr() {
      let avg = 0;

      if (this.getTotalViews && this.getTotalClick) {
        avg = ((this.getTotalClick / this.getTotalViews) * 100).toFixed(2);
      }

      return avg;
    },

    /**
     * @returns {number} float
     */
    calculateEarnings() {
      let total = 0;

      if (this.campaign?.handles[0]?.statsId?.stats?.length) {
        const views = this.campaign.handles[0].statsId.stats.reduce((a, b) => a + (b.views || 0), 0);

        let cpm = this.campaign.info.priceRate;

        if (this.campaign?.handles[0]?.priceRate) {
          cpm = this.campaign.handles[0].priceRate;
        }

        const earned = (views / 1000) * cpm; // (views / 1000) * cpm. Could also be written views/100, but i feel this is more readable.

        total = earned;
      }

      return parseFloat(total.toFixed(2));
    },
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign-info {
  margin: 1rem;
  .field {
    font-weight: 700;
  }
  .value {
    &.link {
      line-break: anywhere;
    }
  }
}

.kpi {
  &--border {
    border: 1px solid rgba($color: #000, $alpha: 0.1);
    border-radius: 5px;
  }
}
</style>
